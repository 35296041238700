(function($) {
	
  var Customjs = {
	  
    setActuHeight: function() {
	    
			var hauteurLigne = $(".block-home").outerHeight();
			if($(window).width() >= 1200) {
				$("#actu-home").css('height',hauteurLigne);
			}
			
			else {
				$("#actu-home").css('height','450px');
			}
	    
	    $(window).load(function(){
			var hauteurLigne = $(".block-home").outerHeight();
			if($(window).width() >= 1200) {
				$("#actu-home").css('height',hauteurLigne);
			}
			
			else {
				$("#actu-home").css('height','450px');
			}
	    });
		
    },
    
    checkRFR: function () {
		$('#revenu-fiscal').focus(function () {
			
			}).blur(function () {
				var rfr = $(this).val();
				console.log(rfr);
				rfr = parseInt(rfr);
				if(rfr < 20000) {
					$('#alert-rfr').css('display','block');
				}
				else {
					$('#alert-rfr').css('display','none');
				}
			});
    },

  };
  

  window.Customjs = Customjs;
  
})(jQuery);
